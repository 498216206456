module.exports = [{
      plugin: require('/home/pau/projects/personal/blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/pau/projects/personal/blog/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-76511857-6","head":false,"anonymize":true},
    },{
      plugin: require('/home/pau/projects/personal/blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
